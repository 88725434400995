require("@fontsource/manrope/500.css")
require("@fontsource/nanum-myeongjo/400.css")
require("./src/css/site.scss")

exports.wrapPageElement = ({ element, props }) => {

}

exports.onRouteUpdate = ({ location }) => {

}
